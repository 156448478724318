import React from "react";

import styles from "../styles/footer.module.css";
import { FaLinkedin } from "react-icons/fa";

export default function FooterComponent() {
  return (
    <section className={styles["footer__section"]}>
      <div className={styles["container"]}>
        <div className={styles["footer__container"]}>
          <h3>© CCX 2024. All rights reserved.</h3>
          {/* <div className={styles["social__links"]}>
            <FaLinkedin size={30} style={{cursor: "pointer"}} />
          </div> */}
        </div>
      </div>
    </section>
  );
}
