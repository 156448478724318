import React from "react";

import styles from "../styles/home.module.css";
import NavbarComponent from "../components/Navbar";
import LandingComponent from "../components/Landing";
import SecurityComponent from "../components/Security";
import BussinessComponents from "../components/Bussiness";
import WhyCBDCXComponent from "../components/WhyCBDCX";
import SupportersComponent from "../components/Supporters";
import ContactSection from "../components/ContactUs";
import FooterComponent from "../components/Footer";
import OurMission from "../components/OurMission";

export default function HomeLayout() {
  return (
    <div className={styles["home__container"]}>
      <div className={styles["home__content_container"]}>
        <NavbarComponent />
        <LandingComponent />
        {/* <SecurityComponent /> */}
        {/* <BussinessComponents /> */}
        {/* <WhyCBDCXComponent /> */}
        {/* <SupportersComponent /> */}
        <ContactSection />
        <OurMission />
        <FooterComponent />
      </div>
    </div>
  );
}
