import React from "react";

import styles from "../styles/contact_us.module.css";
import { JOIN_WISHLIST_LINK } from "../Constants";

export default function ContactSection() {

  const onButtonClick = () => {
    window.open(JOIN_WISHLIST_LINK, "__blank");
  }

  return (
    <section className={styles["contact__section"]}>
      <div className={styles["container"]}>
        <div className={styles["contact__container"]}>
            <h1>Building in Stealth. Get Ready for the Next Revolution in Blockchain. </h1>
            <button onClick={onButtonClick}>Join Waitlist</button>
        </div>
      </div>
    </section>
  );
}
