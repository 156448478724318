import React, { useState } from "react";

import styles from "../styles/navbar.module.css";
import { Menu } from "lucide-react";
import { JOIN_WISHLIST_LINK } from "../Constants";

export default function NavbarComponent() {
  const [dropDown, showDropDown] = useState(false);
  const [height, setHeight] = useState("10vh");

  const onButtonClick = () => {
    window.open(JOIN_WISHLIST_LINK, "__blank");
  }

  return (
    <div className={styles["navbar__container"]}>
      <nav>
        <div className={styles["navbar__content_container"]}>
          <div className={styles["logo__container"]}>
            <h3>.CCX.</h3>
          </div>
          <div className={styles["menu__container"]} id={styles["desktop"]}>
            {/* <ul>
              <li>
                <a href="#">Key Features</a>
              </li>
              <li>
                <a href="#">Use Cases</a>
              </li>
              <li>
                <a href="#">Our Mission</a>
              </li>
            </ul> */}
          </div>
        </div>
        <div className={styles["navbar__actions"]} id={styles["desktop"]}>
          <button className={styles["navbar__get_started"]} onClick={onButtonClick}>
            Join Waitlist
          </button>
        </div>
        {/* <Menu id={styles["mobile"]} onClick={()=>{showDropDown(!dropDown); setHeight("20vh")}} /> */}
      </nav>
      {/* {dropDown && (
        <div className={styles["drop__down_container"]}>
          <ul>
            <li>
              <a href="#">Key Features</a>
            </li>
            <li>
              <a href="#">Use Cases</a>
            </li>
            <li>
              <a href="#">Our Mission</a>
            </li>
          </ul>
        </div>
      )} */}
    </div>
  );
}